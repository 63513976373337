import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import logo from '../assets/img/logo_main.png';
import './Sidebar.css';

const Sidebar: React.FC = () => {
  const [openSection, setOpenSection] = useState<string | null>(null);

  const handleClick = (section: string) => {
    // Se a seção clicada for a mesma, fecha ela; caso contrário, abre a nova
    setOpenSection((prev) => (prev === section ? null : section));
  };

  return (
    <aside>
      <img src={logo} alt="Logo" className="logo" />
      <List component="nav">
        <ListItem button component={Link} to="/">
          <ListItemText primary="Dashboard" style={{ color: '#e2e2e2' }} />
        </ListItem>
        <ListItem button component={Link} to="/fundos_gerenciados">
          <ListItemText primary="Fundos Gerenciados" style={{ color: '#e2e2e2' }} />
        </ListItem>
        <ListItem button component={Link} to="/cadastro_ativos">
          <ListItemText primary="Cadastro de Ativos" style={{ color: '#e2e2e2' }} />
        </ListItem>
        <ListItem button onClick={() => handleClick('cadastroRegras')}>
          <ListItemText primary="Cadastro de Regras" style={{ color: '#e2e2e2' }} />
          {openSection === 'cadastroRegras' ? (
            <ArrowDropDownIcon style={{ color: '#e2e2e2' }} />
          ) : (
            <ArrowRightIcon style={{ color: '#e2e2e2' }} />
          )}
        </ListItem>
        <Collapse in={openSection === 'cadastroRegras'} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button component={Link} to="/cadastro_regras/cvm" sx={{ pl: 4 }}>
              <ListItemText primary="CVM" style={{ color: '#e2e2e2' }} />
            </ListItem>
            <ListItem button component={Link} to="/cadastro_regras/regulamento" sx={{ pl: 4 }}>
              <ListItemText primary="Regulamento" style={{ color: '#e2e2e2' }}/>
            </ListItem>
          </List>
        </Collapse>
        <ListItem button onClick={() => handleClick('carteiraDiaria')}>
          <ListItemText primary="Carteira Diária" style={{ color: '#e2e2e2' }} />
          {openSection === 'carteiraDiaria' ? (
            <ArrowDropDownIcon style={{ color: '#e2e2e2' }} />
          ) : (
            <ArrowRightIcon style={{ color: '#e2e2e2' }} />
          )}
        </ListItem>
        <Collapse in={openSection === 'carteiraDiaria'} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button component={Link} to="/carteira_diaria/" sx={{ pl: 4 }}>
              <ListItemText primary="Carteira" style={{ color: '#e2e2e2' }} />
            </ListItem>
            <ListItem button component={Link} to="/carteira_diaria/resumo_diario" sx={{ pl: 4 }}>
              <ListItemText primary="Resumo Diário" style={{ color: '#e2e2e2' }} />
            </ListItem>
            <ListItem button component={Link} to="/carteira_diaria/ativos" sx={{ pl: 4 }}>
              <ListItemText primary="Ativos" style={{ color: '#e2e2e2' }}/>
            </ListItem>
            <ListItem button component={Link} to="/carteira_diaria/passivos" sx={{ pl: 4 }}>
              <ListItemText primary="Passivos" style={{ color: '#e2e2e2' }}/>
            </ListItem>
            <ListItem button component={Link} to="/carteira_diaria/boletagem" sx={{ pl: 4 }}>
              <ListItemText primary="Boletagem Pré-Trade" style={{ color: '#e2e2e2' }}/>
            </ListItem>
            <ListItem button component={Link} to="/carteira_diaria/patrimonio" sx={{ pl: 4 }}>
              <ListItemText primary="Patrimônio Líquido" style={{ color: '#e2e2e2' }}/>
            </ListItem>
            <ListItem button component={Link} to="/carteira_diaria/saldotesouraria" sx={{ pl: 4 }}>
              <ListItemText primary="Caixa e/ou Tesouraria" style={{ color: '#e2e2e2' }}/>
            </ListItem>
          </List>
        </Collapse>
        <ListItem button onClick={() => handleClick('ativos')}>
          <ListItemText primary="Ativos" style={{ color: '#e2e2e2' }}/>
          {openSection === 'ativos' ? (
            <ArrowDropDownIcon style={{ color: '#e2e2e2' }} />
          ) : (
            <ArrowRightIcon style={{ color: '#e2e2e2' }} />
          )}
        </ListItem>
        <Collapse  in={openSection === 'ativos'} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button component={Link} to="/ativos/fundos_investimento" sx={{ pl: 4 }}>
              <ListItemText primary="Fundos de Investimento" style={{ color: '#e2e2e2' }}/>
            </ListItem>
            <ListItem button component={Link} to="/ativos/rendafixa_titulo_publico" sx={{ pl: 4 }}>
              <ListItemText primary="Renda Fixa - Título Público" style={{ color: '#e2e2e2' }}/>
            </ListItem>
            <ListItem button component={Link} to="/ativos/compromissada" sx={{ pl: 4 }}>
              <ListItemText primary="Compromissada" style={{ color: '#e2e2e2' }}/>
            </ListItem>
            <ListItem button component={Link} to="/ativos/rendafixa_bancario" sx={{ pl: 4 }}>
              <ListItemText primary="Renda Fixa - Bancario" style={{ color: '#e2e2e2' }}/>
            </ListItem>
            <ListItem button component={Link} to="/ativos/rendafixa_corporativo" sx={{ pl: 4 }}>
              <ListItemText primary="Renda Fixa - Corporativo" style={{ color: '#e2e2e2' }}/>
            </ListItem>
            <ListItem button component={Link} to="/ativos/rendafixa_securitizado" sx={{ pl: 4 }}>
              <ListItemText primary="Renda Fixa - Securitizado" style={{ color: '#e2e2e2' }}/>
            </ListItem>
            <ListItem button component={Link} to="/ativos/renda_variavel" sx={{ pl: 4 }}>
              <ListItemText primary="Renda Variável" style={{ color: '#e2e2e2' }} /> 
            </ListItem>
            <ListItem button component={Link} to="/ativos/adr" sx={{ pl: 4 }}>
              <ListItemText primary="ADR e Ações Offshore" style={{ color: '#e2e2e2' }}/>
            </ListItem>
          </List>
        </Collapse>
        <ListItem button component={Link} to="/passivos">
          <ListItemText primary="Passivos" style={{ color: '#e2e2e2' }}/>
        </ListItem>
        
      </List>
    </aside>
  );
};

export default Sidebar;