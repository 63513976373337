import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { AuthProvider } from './context/authContext';
import BaseLayout from './components/Base'; // O layout para as páginas principais
import Home from './pages/Home';
import FundosGerenciados from './pages/FundosGerenciados';
import FundoInvestimento from './pages/FundoInvestimento';
import RendaFixa_TituloPublico from './pages/RendaFixaTituloPublico';
import CompromissadaPage from './pages/Compromissada';
import RendaFixa_Bancario from './pages/RendaFixaBancario';
import RendaFixa_Corporativo from './pages/RendaFixaCorporativo';
import RendaFixa_Securitizado from './pages/RendaFixaSecuritizado';
import Renda_Variavel from './pages/RendaVariavel';
import Passivos from './pages/Passivos';
import ADR_Offshore from './pages/ADR';
import CarteiraDiaria_Ativos from './pages/CarteiraDiariaAtivos';
import CarteiraDiaria_Passivos from './pages/CarteiraDiariaPassivos';
import CarteiraDiaria_PL from './pages/CarteiraDiariaPatrimonioLiquido';
import CarteiraDiaria_SaldoTesouraria from './pages/CarteiraDiariaTesouraria';
import CarteiraDiariaConsulta from './pages/CarteiraDiariaConsulta';
import CadastroAtivos from './pages/CadastroAtivos';
import LoginPage from './pages/LoginPage'; // Importa a página de login
import ProtectedRoute from './services/ProtectedRoute'; // Componente que protege rotas
import UserCRUD from './pages/UserCRUD';
import CadastroRegrasCVM from './pages/CadastroRegrasCVM';
import CadastroRegrasRegulamento from './pages/CadastroRegrasRegulamento';
import Boletagem from './pages/Boletagem';
import ResumoDiarioConsulta from './pages/ResumoDiarioConsulta';
import ParametrosList from './pages/ParametrosList'
import SegmentosList from './pages/SegmentosList';
import TiposAtivosList from './pages/TipoAtivoList';

const App: React.FC = () => {
  return (
    <AuthProvider>
      <Routes>
        {/* Página de login - rota pública */}
        <Route path="/login" element={<LoginPage />} />

        {/* Rotas protegidas */}
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <BaseLayout><Home /></BaseLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/fundos_gerenciados"
          element={
            <ProtectedRoute>
              <BaseLayout><FundosGerenciados /></BaseLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/cadastro_ativos"
          element={
            <ProtectedRoute>
              <BaseLayout><CadastroAtivos /></BaseLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/cadastro_regras/cvm"
          element={
            <ProtectedRoute>
              <BaseLayout><CadastroRegrasCVM /></BaseLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/cadastro_regras/regulamento"
          element={
            <ProtectedRoute>
              <BaseLayout><CadastroRegrasRegulamento /></BaseLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/carteira_diaria/boletagem"
          element={
            <ProtectedRoute>
              <BaseLayout><Boletagem /></BaseLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/carteira_diaria"
          element={
            <ProtectedRoute>
              <BaseLayout><CarteiraDiariaConsulta /></BaseLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/carteira_diaria/resumo_diario"
          element={
            <ProtectedRoute>
              <BaseLayout><ResumoDiarioConsulta /></BaseLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/carteira_diaria/ativos"
          element={
            <ProtectedRoute>
              <BaseLayout><CarteiraDiaria_Ativos /></BaseLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/carteira_diaria/passivos"
          element={
            <ProtectedRoute>
              <BaseLayout><CarteiraDiaria_Passivos /></BaseLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/carteira_diaria/patrimonio"
          element={
            <ProtectedRoute>
              <BaseLayout><CarteiraDiaria_PL /></BaseLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/carteira_diaria/saldotesouraria"
          element={
            <ProtectedRoute>
              <BaseLayout><CarteiraDiaria_SaldoTesouraria /></BaseLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/ativos/fundos_investimento"
          element={
            <ProtectedRoute>
              <BaseLayout><FundoInvestimento /></BaseLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/ativos/rendafixa_titulo_publico"
          element={
            <ProtectedRoute>
              <BaseLayout><RendaFixa_TituloPublico /></BaseLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/ativos/compromissada"
          element={
            <ProtectedRoute>
              <BaseLayout><CompromissadaPage /></BaseLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/ativos/rendafixa_bancario"
          element={
            <ProtectedRoute>
              <BaseLayout><RendaFixa_Bancario /></BaseLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/ativos/rendafixa_corporativo"
          element={
            <ProtectedRoute>
              <BaseLayout><RendaFixa_Corporativo /></BaseLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/ativos/rendafixa_securitizado"
          element={
            <ProtectedRoute>
              <BaseLayout><RendaFixa_Securitizado /></BaseLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/ativos/renda_variavel"
          element={
            <ProtectedRoute>
              <BaseLayout><Renda_Variavel /></BaseLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/ativos/adr"
          element={
            <ProtectedRoute>
              <BaseLayout><ADR_Offshore /></BaseLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/passivos"
          element={
            <ProtectedRoute>
              <BaseLayout><Passivos /></BaseLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/config/usuarios"
          element={
            <ProtectedRoute>
              <BaseLayout><UserCRUD /></BaseLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/config/parametros"
          element={
            <ProtectedRoute>
              <BaseLayout><ParametrosList/></BaseLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/config/parametros/segmentos/"
          element={
            <ProtectedRoute>
              <BaseLayout><SegmentosList/></BaseLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/config/parametros/tipos_ativos/"
          element={
            <ProtectedRoute>
              <BaseLayout><TiposAtivosList/></BaseLayout>
            </ProtectedRoute>
          }
        />
      </Routes>
    </AuthProvider>
  );
};

export default App;
