import React, { createContext, useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { authService } from '../services/authService';

interface AuthContextType {
  isAuthenticated: boolean;
  loading: boolean;
  login: (email: string, password: string) => Promise<void>;
  logout: () => void;
}

const AuthContext = createContext<AuthContextType | null>(null);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true); // Gerencia o carregamento inicial
  const navigate = useNavigate();

  // Valida o token na inicialização
  useEffect(() => {
    const initializeAuth = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        console.log('Nenhum token encontrado.');
        setIsAuthenticated(false);
        setLoading(false); // Finaliza o carregamento
        return;
      }
  
      const isValid = validateToken(token);
      setIsAuthenticated(isValid);
      setLoading(false);
    };
  
    initializeAuth();
  }, []);

  const login = async (email: string, password: string) => {
    try {
      await authService.login(email, password); // Chama o serviço de autenticação
  
      const token = localStorage.getItem('token');
      if (!token) {
        setIsAuthenticated(false);
        return;
      }
  
      const isValid = validateToken(token); // Valida o token
      setIsAuthenticated(isValid); // Atualiza o estado global
    } catch (err) {
      console.error('Erro ao fazer login:', err);
      throw new Error('Falha no login.');
    }
  };
  const logout = () => {
    console.log('Efetuando logout...');
    authService.logout();
    setIsAuthenticated(false);
    navigate('/login');
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, loading, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth deve ser usado dentro de um AuthProvider');
  }
  return context;
};

const validateToken = (token: string): boolean => {
  if (!token) {
    console.log('Token vazio ou inválido.');
    return false;
  }
  try {
    const payload = JSON.parse(atob(token.split('.')[1])); // Decodifica o payload JWT
    const isExpired = payload.exp * 1000 < Date.now(); // Verifica se o token expirou
    return !isExpired;
  } catch (err) {
    console.error('Erro ao validar token:', err);
    return false; // Retorna falso se a validação falhar
  }
};