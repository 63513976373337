import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Select,
  MenuItem,
  IconButton,
  Typography,
  Box,
  Divider,
} from '@mui/material';
import { Add, Delete } from '@mui/icons-material';
import { 
  getOpcoesDescricao, 
  createOpcaoDescricao, 
  getAtivos, 
  getPerfisEmpresa, 
  createPerfisEmpresa, 
  getFundoInvestimentoRegras, 
  saveFundoInvestimentoRegras 
} from '../../services/ativosService';
import {
  OpcaoDescricao,
  Grupo,
  AtivoGrupo,
  Emissor,
  InvestimentoExterior,
  InstrumentoDerivativo,
  OutraEstrategia,
  GrupoOperacaoLigada,
  PerfisEmpresas,
  FundoInvestimentoRegras,
} from '../../types/ativos';

interface EditRegraModalAtivosProps {
  isOpen: boolean;
  onRequestClose: () => void;
  rowData: any;
  segmentoNome?: string;
}

interface ErrorState {
  grupos: (string | "")[];
  gruposOperacoesLigadas: (string | "")[];
}

// Definindo um tipo com ID opcional para novos objetos
const EditRegraModalAtivos: React.FC<EditRegraModalAtivosProps> = ({
  isOpen,
  onRequestClose,
  rowData,
  segmentoNome,
}) => {
  const [grupos, setGrupos] = useState<Grupo[]>([]);
  const [opcoesDescricao, setOpcoesDescricao] = useState<OpcaoDescricao[]>([]);
  const [perfisEmpresas, setPerfisEmpresas] = useState<PerfisEmpresas[]>([]);
  const [ativos, setAtivos] = useState<{ id: number; nome: string }[]>([]);
  const [emissores, setEmissores] = useState<Emissor[]>([]);
  const [investimentosExterior, setInvestimentosExterior] = useState<InvestimentoExterior[]>([]);
  const [instrumentosDerivativos, setInstrumentosDerivativos] = useState<InstrumentoDerivativo[]>([]);
  const [outrasEstrategias, setOutrasEstrategias] = useState<OutraEstrategia[]>([]);
  const [gruposOperacoesLigadas, setGruposOperacoesLigadas] = useState<GrupoOperacaoLigada[]>([]);
  const [novaOpcao, setNovaOpcao] = useState('');
  const [novoPerfil, setNovoPerfil] = useState('');
  const [dialogOpcaoAberto, setDialogOpcaoAberto] = useState(false);
  const [dialogPerfilAberto, setDialogPerfilAberto] = useState(false);
  const [errors, setErrors] = useState<ErrorState>({ grupos: [""], gruposOperacoesLigadas: [""] });
  const [canSave, setCanSave] = useState(true);
  const validateLimites = () => {
    // Validação dos grupos de ativos
    const grupoErrors: string[] = grupos.map((grupo) => {
      const grupoLimite = grupo.limite_maximo || 0;
  
      // Verificar se algum ativo excede o limite do grupo
      const anyExceeds = grupo.grupos_ativos?.some((ativo) => (ativo.limite_maximo || 0) > grupoLimite);
      return anyExceeds ? 'Limite de um ativo excede o limite do grupo' : '';
    });
  
    // Validação dos grupos de operações ligadas
    const grupoLigadoErrors: string[] = gruposOperacoesLigadas.map((grupoLigado) => {
      const grupoLigadoLimite = grupoLigado.limite_maximo || 0;
  
      // Verificar se alguma operação excede o limite do grupo de operações ligadas
      const anyExceeds = grupoLigado.operacoes_ligadas?.some((operacao) => (operacao.limite_maximo || 0) > grupoLigadoLimite);
      return anyExceeds ? 'Limite de uma operação excede o limite do grupo' : '';
    });
  
    setErrors({ grupos: grupoErrors, gruposOperacoesLigadas: grupoLigadoErrors });
    setCanSave(grupoErrors.every((err) => !err) && grupoLigadoErrors.every((err) => !err));
  };
  

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const opcoes = await getOpcoesDescricao();
        const perfis = await getPerfisEmpresa();
        const ativosDisponiveis = await getAtivos();
        
        setOpcoesDescricao(opcoes);
        setPerfisEmpresas(perfis);
        setAtivos(ativosDisponiveis);

        const fundoId = rowData?.id;
        if (isOpen && fundoId) {
          const fundoData = await getFundoInvestimentoRegras(fundoId);
          setGrupos(
            (fundoData.grupos || []).map((grupo: Grupo) => ({
              ...grupo,
              id: grupo.id || Date.now() + Math.random(), // ID temporário
              fundo: grupo.fundo ?? rowData.id, // Use o fundo do grupo ou o ID do fundo atual
              ativos: grupo.grupos_ativos ? grupo.grupos_ativos.map((ativo) => ({
                id: ativo.id,
                grupo: ativo.grupo,
                ativo: ativo.ativo ?? 0, // Define `0` se `ativo` estiver nulo
                limite_maximo: ativo.limite_maximo,
              })) : [],
              limite_maximo: grupo.limite_maximo ?? null, // Define como `null` se estiver `undefined`
              opcao: grupo.opcao ?? null, // Pega apenas o ID da `opcao`
            }))
          );
          setEmissores(fundoData.emissores || []);
          setInvestimentosExterior(fundoData.investimentos_exterior || []);
          setInstrumentosDerivativos(fundoData.instrumentos_derivativos || []);
          setOutrasEstrategias(fundoData.outras_estrategias || []);
          setGruposOperacoesLigadas(
            (fundoData.grupos_ligadas || []).map((grupoLigado: any) => ({
              ...grupoLigado,
              id: grupoLigado.id || Date.now() + Math.random(),
              fundo: grupoLigado.fundo ?? rowData.id,
              opcao: typeof grupoLigado.opcao === "object" ? grupoLigado.opcao.id : grupoLigado.opcao,
              operacoes_ligadas: grupoLigado.operacoes_ligadas || [],
            }))
          );
          validateLimites();
        }
      } catch (error) {
        console.error('Erro ao buscar dados iniciais:', error);
      }
    };

    fetchInitialData();
  }, [isOpen, rowData]);

  useEffect(() => {
    validateLimites(); // Validar limites quando os dados de grupo/operacoes mudarem
  }, [grupos, gruposOperacoesLigadas]);

  const handleSave = () => {
    // Verifica se o salvamento é permitido (se não houver erros de validação)
    if (!canSave) {
      alert("Corrija os erros de limite antes de salvar.");
      return;
    }
  
    // Estruturação do payload
    const payload: FundoInvestimentoRegras = {
      grupos: grupos.map((grupo) => ({
        id: grupo.id,
        fundo: rowData.id,
        opcao: grupo.opcao,
        limite_maximo: grupo.limite_maximo ?? null,
        grupos_ativos: (grupo.grupos_ativos || []).map((ativo) => ({
          ativo: ativo.ativo ?? 0,
          limite_maximo: ativo.limite_maximo,
        })),
      })),
      emissores: emissores.map((emissor) => ({
        id: emissor.id,
        fundo: rowData.id,
        descricao: emissor.descricao,
        titulo: emissor.titulo,
        limite_maximo: emissor.limite_maximo,
      })),
      investimentos_exterior: investimentosExterior.map((investimento) => ({
        id: investimento.id,
        fundo: rowData.id,
        descricao: investimento.descricao,
        titulo: investimento.titulo,
        limite_maximo: investimento.limite_maximo,
      })),
      instrumentos_derivativos: instrumentosDerivativos.map((instrumento) => ({
        id: instrumento.id,
        fundo: rowData.id,
        descricao: instrumento.descricao,
        titulo: instrumento.titulo,
        limite_maximo: instrumento.limite_maximo,
      })),
      outras_estrategias: outrasEstrategias.map((estrategia) => ({
        id: estrategia.id,
        fundo: rowData.id,
        descricao: estrategia.descricao,
        titulo: estrategia.titulo,
        limite_maximo: estrategia.limite_maximo,
      })),
      grupos_ligadas: gruposOperacoesLigadas.map((grupoLigado) => ({
        id: grupoLigado.id,
        fundo: rowData.id,
        opcao: grupoLigado.opcao, // Apenas o ID como número
        limite_maximo: grupoLigado.limite_maximo ?? null,
        operacoes_ligadas: grupoLigado.operacoes_ligadas.map((operacao) => ({  
          descricao: operacao.descricao,
          limite_maximo: operacao.limite_maximo,
        })),
      })),
    };
  
    // Salva os dados usando a função de serviço (assíncrona)
    saveFundoInvestimentoRegras(rowData.id, payload)
      .then(() => {
        onRequestClose(); // Fecha o modal após o salvamento
      })
      .catch((error) => {
        console.error('Erro ao salvar os dados:', error);
      });
  };

  const adicionarGrupo = () => {
    setGrupos([
      ...grupos,
      {
        id: Date.now() + Math.random(), // ID temporário para novo grupo
        ativos: [],
        opcao: null,
        limite_maximo: null,
        fundo: rowData.id, // Inclua o ID do fundo se necessário
      },
    ] as Grupo[]);
  };
  
  const adicionarAtivo = (grupoIndex: number) => {
    const novosGrupos = [...grupos];
    const grupo = novosGrupos[grupoIndex];
    if (grupo) {
      grupo.grupos_ativos = grupo.grupos_ativos || [];
      grupo.grupos_ativos.push({
        grupo: typeof grupo.id === "number" ? grupo.id : 0, // Associe o ID do grupo
        ativo: 0, // ID padrão ou null, será atualizado
        limite_maximo: null,
      });
      setGrupos(novosGrupos);
    }
  };

  const adicionarEmissor = () => {
    setEmissores([
      ...emissores,
      { fundo: rowData.id, descricao: '', titulo: '', limite_maximo: null }
    ]);
  };

  const adicionarInvestimentoExterior = () => {
    setInvestimentosExterior([
      ...investimentosExterior,
      { fundo: rowData.id, descricao: '', titulo: '', limite_maximo: null }
    ]);
  };

  const adicionarInstrumentoDerivativo = () => {
    setInstrumentosDerivativos([
      ...instrumentosDerivativos,
      { fundo: rowData.id, descricao: '', titulo: '', limite_maximo: null }
    ]);
  };

  const adicionarOutraEstrategia = () => {
    setOutrasEstrategias([
      ...outrasEstrategias,
      { fundo: rowData.id, descricao: '', titulo: '', limite_maximo: null }
    ]);
  };

  const handleAddOpcao = async () => {
    try {
      const novaOpcaoCriada = await createOpcaoDescricao(novaOpcao);
      setOpcoesDescricao([...opcoesDescricao, novaOpcaoCriada]);
      setDialogOpcaoAberto(false);
      setNovaOpcao('');
    } catch (error) {
      console.error('Erro ao criar nova opção:', error);
    }
  };

  const handleAddPerfil = async () => {
    try {
      const perfilCriado = await createPerfisEmpresa(novoPerfil);
      setPerfisEmpresas([...perfisEmpresas, perfilCriado]);
      setDialogPerfilAberto(false);
      setNovoPerfil('');
    } catch (error) {
      console.error("Erro ao criar novo perfil:", error);
    }
  };

  const adicionarGrupoOperacaoLigada = () => {
    setGruposOperacoesLigadas([
      ...gruposOperacoesLigadas,
      {
        id: Date.now() + Math.random(), // ID temporário para novo grupo ligado
        fundo: rowData.id,
        opcao: null,
        limite_maximo: null,
        sequencia: gruposOperacoesLigadas.length + 1,
        operacoes_ligadas: [],
      },
    ]);
  };

  return (
    <Dialog open={isOpen} onClose={onRequestClose} fullWidth maxWidth="md">
      <DialogTitle>{segmentoNome ? `Editar ${segmentoNome}` : 'Editar Regras'}</DialogTitle>
      <DialogContent>
        {/* Renderização dos Grupos e Ativos dentro dos Grupos */}
        <Typography variant="h6" gutterBottom>Grupos</Typography>
        {grupos.map((grupo, grupoIndex) => (
          <Box key={grupo.id ?? grupoIndex} mb={3} p={2} border={1} borderRadius={2}>
            <Typography variant="h6">Grupo {grupoIndex + 1}</Typography>
            <Box display="flex" alignItems="center" gap={2} mt={2}>
            <Select
              value={grupo.opcao ?? ''} // usa ID diretamente ou string vazia
              onChange={(e) => {
                const opcaoId = Number(e.target.value);
                if (opcaoId === -1) {
                  setDialogOpcaoAberto(true); // Abre o modal para adicionar nova opção
                } else {
                const novosGrupos = [...grupos];
                novosGrupos[grupoIndex].opcao = opcaoId; // Atribui apenas o ID
                setGrupos(novosGrupos);
                validateLimites();
                }
              }}
              fullWidth
              displayEmpty
            >
              <MenuItem value="" disabled>Selecione uma opção</MenuItem>
              {opcoesDescricao.map(opcao => (
                <MenuItem key={opcao.id} value={opcao.id}>{opcao.descricao}</MenuItem>
              ))}
              <MenuItem value={-1}>+ Adicionar nova opção</MenuItem>
              </Select>
              <TextField
                label="Limite Máximo (%)"
                type="number"
                value={grupo.limite_maximo || ''}
                onChange={(e) => {
                  const novosGrupos = [...grupos];
                  novosGrupos[grupoIndex].limite_maximo = Number(e.target.value);
                  setGrupos(novosGrupos);
                  validateLimites();
                }}
                fullWidth
              />
              <IconButton onClick={() => setGrupos(grupos.filter((_, i) => i !== grupoIndex))} color="secondary">
                <Delete />
              </IconButton>
            </Box>
            {errors.grupos[grupoIndex] && (
              <Typography color="error" variant="body2" mt={1}>
                {errors.grupos[grupoIndex]}
              </Typography>
            )}
            <Typography variant="subtitle1" mt={2}>Ativos do Grupo</Typography>
            {grupo.grupos_ativos?.map((ativo, ativoIndex) => (
              <Box key={ativo.id ?? ativoIndex} display="flex" alignItems="center" gap={2} mt={1}>
                <Select
                  value={ativo.ativo || ''} 
                  onChange={(e) => {
                    const ativoId = Number(e.target.value);
                    const novosGrupos = [...grupos];
                    if (novosGrupos[grupoIndex]?.grupos_ativos) {
                      novosGrupos[grupoIndex].grupos_ativos![ativoIndex] = {
                        ...novosGrupos[grupoIndex].grupos_ativos![ativoIndex],
                        ativo: ativoId, // Atualiza o ID do ativo selecionado
                      };
                    }
                    setGrupos(novosGrupos);
                  }}
                  fullWidth
                  displayEmpty
                >
                  <MenuItem value="" disabled>Selecione um ativo</MenuItem>
                  {ativos.map((ativoItem) => (
                    <MenuItem key={ativoItem.id} value={ativoItem.id}>{ativoItem.nome}</MenuItem>
                  ))}
                </Select>
                <TextField
                  label="Limite Máximo (%)"
                  type="number"
                  value={ativo.limite_maximo || ''}
                  onChange={(e) => {
                    const novosGrupos = [...grupos];
                    if (novosGrupos[grupoIndex]?.grupos_ativos?.[ativoIndex]) {
                      novosGrupos[grupoIndex].grupos_ativos![ativoIndex].limite_maximo = Number(e.target.value);
                      setGrupos(novosGrupos);
                    }
                  }}
                  fullWidth
                />
                <IconButton onClick={() => {
                  const novosGrupos = [...grupos];
                  if (novosGrupos[grupoIndex]?.grupos_ativos) {
                    novosGrupos[grupoIndex].grupos_ativos = novosGrupos[grupoIndex].grupos_ativos?.filter((_, i) => i !== ativoIndex) || [];
                    setGrupos(novosGrupos); 
                  }
                }} color="secondary">
                  <Delete />
                </IconButton>
              </Box>
            ))}
            <Button
              onClick={() => adicionarAtivo(grupoIndex)}
              variant="outlined"
              startIcon={<Add />}
              fullWidth
              sx={{ mt: 1 }}
            >
              Adicionar Ativo
            </Button>
          </Box>
        ))}
        <Button onClick={adicionarGrupo} variant="contained" fullWidth sx={{ mt: 2 }}>
          Adicionar Grupo
        </Button>

        <Divider sx={{ my: 3 }} />

        {/* Emissores */}
        <Typography variant="h6" gutterBottom>Emissores</Typography>
        {emissores.map((emissor, index) => (
          <Box key={index} display="flex" alignItems="center" gap={2} mt={2}>
            <TextField
              label="Descrição do Emissor"
              value={emissor.descricao}
              onChange={(e) => {
                const novosEmissores = [...emissores];
                novosEmissores[index].descricao = e.target.value;
                setEmissores(novosEmissores);
              }}
              fullWidth
            />
            <TextField
              label="Limite Máximo (%)"
              type="number"
              value={emissor.limite_maximo || ''}
              onChange={(e) => {
                const novosEmissores = [...emissores];
                novosEmissores[index].limite_maximo = Number(e.target.value);
                setEmissores(novosEmissores);
              }}
              fullWidth
            />
            <IconButton onClick={() => setEmissores(emissores.filter((_, i) => i !== index))} color="secondary">
              <Delete />
            </IconButton>
          </Box>
        ))}
        <Button onClick={adicionarEmissor} variant="outlined" fullWidth sx={{ mt: 2 }}>
          Adicionar Emissor
        </Button>

        <Divider sx={{ my: 3 }} />

        {/* Investimentos no Exterior */}
        <Typography variant="h6" gutterBottom>Investimentos no Exterior</Typography>
        {investimentosExterior.map((investimento, index) => (
          <Box key={index} display="flex" alignItems="center" gap={2} mt={2}>
            <TextField
              label="Descrição do Investimento"
              value={investimento.descricao}
              onChange={(e) => {
                const novosInvestimentos = [...investimentosExterior];
                novosInvestimentos[index].descricao = e.target.value;
                setInvestimentosExterior(novosInvestimentos);
              }}
              fullWidth
            />
            <TextField
              label="Limite Máximo (%)"
              type="number"
              value={investimento.limite_maximo || ''}
              onChange={(e) => {
                const novosInvestimentos = [...investimentosExterior];
                novosInvestimentos[index].limite_maximo = Number(e.target.value);
                setInvestimentosExterior(novosInvestimentos);
              }}
              fullWidth
            />
            <IconButton onClick={() => setInvestimentosExterior(investimentosExterior.filter((_, i) => i !== index))} color="secondary">
              <Delete />
            </IconButton>
          </Box>
        ))}
        <Button onClick={adicionarInvestimentoExterior} variant="outlined" fullWidth sx={{ mt: 2 }}>
          Adicionar Investimento Exterior
        </Button>

        <Divider sx={{ my: 3 }} />

        {/* Instrumentos Derivativos */}
        <Typography variant="h6" gutterBottom>Instrumentos Derivativos</Typography>
        {instrumentosDerivativos.map((instrumento, index) => (
          <Box key={index} display="flex" alignItems="center" gap={2} mt={2}>
            <TextField
              label="Descrição do Instrumento"
              value={instrumento.descricao}
              onChange={(e) => {
                const novosInstrumentos = [...instrumentosDerivativos];
                novosInstrumentos[index].descricao = e.target.value;
                setInstrumentosDerivativos(novosInstrumentos);
              }}
              fullWidth
            />
            <TextField
              label="Limite Máximo (%)"
              type="number"
              value={instrumento.limite_maximo || ''}
              onChange={(e) => {
                const novosInstrumentos = [...instrumentosDerivativos];
                novosInstrumentos[index].limite_maximo = Number(e.target.value);
                setInstrumentosDerivativos(novosInstrumentos);
              }}
              fullWidth
            />
            <IconButton onClick={() => setInstrumentosDerivativos(instrumentosDerivativos.filter((_, i) => i !== index))} color="secondary">
              <Delete />
            </IconButton>
          </Box>
        ))}
        <Button onClick={adicionarInstrumentoDerivativo} variant="outlined" fullWidth sx={{ mt: 2 }}>
          Adicionar Instrumento Derivativo
        </Button>

        <Divider sx={{ my: 3 }} />

        {/* Outras Estratégias */}
        <Typography variant="h6" gutterBottom>Outras Estratégias</Typography>
        {outrasEstrategias.map((estrategia, index) => (
          <Box key={index} display="flex" alignItems="center" gap={2} mt={2}>
            <TextField
              label="Descrição da Estratégia"
              value={estrategia.descricao}
              onChange={(e) => {
                const novasEstrategias = [...outrasEstrategias];
                novasEstrategias[index].descricao = e.target.value;
                setOutrasEstrategias(novasEstrategias);
              }}
              fullWidth
            />
            <TextField
              label="Limite Máximo (%)"
              type="number"
              value={estrategia.limite_maximo || ''}
              onChange={(e) => {
                const novasEstrategias = [...outrasEstrategias];
                novasEstrategias[index].limite_maximo = Number(e.target.value);
                setOutrasEstrategias(novasEstrategias);
              }}
              fullWidth
            />
            <IconButton onClick={() => setOutrasEstrategias(outrasEstrategias.filter((_, i) => i !== index))} color="secondary">
              <Delete />
            </IconButton>
          </Box>
        ))}
        <Button onClick={adicionarOutraEstrategia} variant="outlined" fullWidth sx={{ mt: 2 }}>
          Adicionar Outra Estratégia
        </Button>
        <Divider sx={{ my: 3 }} />
        <Typography variant="h6" gutterBottom>Grupos de Operações Ligadas</Typography>
        {gruposOperacoesLigadas.map((grupo, grupoIndex) => (
          <Box key={grupoIndex} mb={3} p={2} border={1} borderRadius={2}>
            <Typography variant="h6">G{grupoIndex + 1}</Typography>
            <Box display="flex" alignItems="center" gap={2} mt={2}>
              <Select
                value={grupo.opcao || ''} // Usa o `id` diretamente ou uma string vazia
                onChange={(e) => {
                  const perfilId = Number(e.target.value);
                  if (perfilId === -1) {
                    setDialogPerfilAberto(true); // Abre o modal para adicionar novo perfil
                  } else {
                  const novosGruposOperacoes = [...gruposOperacoesLigadas];
                  novosGruposOperacoes[grupoIndex].opcao = perfilId === -1 ? null : perfilId;
                  setGruposOperacoesLigadas(novosGruposOperacoes);
                  validateLimites();
                  }
                }}
                fullWidth
                displayEmpty
              >
                <MenuItem value="" disabled>Selecione um perfil de empresa</MenuItem>
                {perfisEmpresas.map((perfil) => (
                  <MenuItem key={perfil.id} value={perfil.id}>
                    {perfil.descricao}
                  </MenuItem>
                ))}
                <MenuItem value={-1}>+ Adicionar novo perfil</MenuItem>
              </Select>
              <TextField
                label="Limite Máximo (%)"
                type="number"
                value={grupo.limite_maximo || ''}
                onChange={(e) => {
                  const novosGruposOperacoes = [...gruposOperacoesLigadas];
                  novosGruposOperacoes[grupoIndex].limite_maximo = Number(e.target.value);
                  setGruposOperacoesLigadas(novosGruposOperacoes);
                  validateLimites();
                }}
                fullWidth
              />
              <IconButton onClick={() => setGruposOperacoesLigadas(gruposOperacoesLigadas.filter((_, i) => i !== grupoIndex))} color="secondary">
                <Delete />
              </IconButton>
            </Box>
            {errors.gruposOperacoesLigadas[grupoIndex] && (
              <Typography color="error" variant="body2" mt={1}>
                {errors.gruposOperacoesLigadas[grupoIndex]}
              </Typography>
            )}

            {/* Operações Ligadas dentro de cada Grupo */}
            <Typography variant="subtitle1" mt={2}>Empresas Ligadas</Typography>
            {grupo.operacoes_ligadas.map((operacao, operacaoIndex) => (
              <Box key={operacaoIndex} display="flex" alignItems="center" gap={2} mt={1}>
                <TextField
                  label="Descrição da Operação"
                  value={operacao.descricao}
                  onChange={(e) => {
                    const novosGruposOperacoes = [...gruposOperacoesLigadas];
                    novosGruposOperacoes[grupoIndex].operacoes_ligadas[operacaoIndex].descricao = e.target.value;
                    setGruposOperacoesLigadas(novosGruposOperacoes);
                  }}
                  fullWidth
                />
                <TextField
                  label="Limite Máximo (%)"
                  type="number"
                  value={operacao.limite_maximo || ''}
                  onChange={(e) => {
                    const novosGruposOperacoes = [...gruposOperacoesLigadas];
                    novosGruposOperacoes[grupoIndex].operacoes_ligadas[operacaoIndex].limite_maximo = Number(e.target.value);
                    setGruposOperacoesLigadas(novosGruposOperacoes);
                  }}
                  fullWidth
                />
                <IconButton onClick={() => {
                  const novosGruposOperacoes = [...gruposOperacoesLigadas];
                  novosGruposOperacoes[grupoIndex].operacoes_ligadas = novosGruposOperacoes[grupoIndex].operacoes_ligadas.filter((_, i) => i !== operacaoIndex);
                  setGruposOperacoesLigadas(novosGruposOperacoes);
                }} color="secondary">
                  <Delete />
                </IconButton>
              </Box>
            ))}
            <Button
              onClick={() => {
                const novosGruposOperacoes = [...gruposOperacoesLigadas];
                novosGruposOperacoes[grupoIndex].operacoes_ligadas.push({
                  grupo: typeof grupo.id === 'number' ? grupo.id : 0, // Usar ID real ou temporário
                  descricao: '',
                  limite_maximo: null,
                });
                setGruposOperacoesLigadas(novosGruposOperacoes);
              }}
              variant="outlined"
              startIcon={<Add />}
              fullWidth
              sx={{ mt: 1 }}
            >
              Adicionar Operação Ligada
          </Button>
          </Box>
        ))}
        <Button onClick={adicionarGrupoOperacaoLigada} variant="contained" fullWidth sx={{ mt: 2 }}>
          Adicionar Grupo de Operação Ligada
        </Button>
      </DialogContent>
      <DialogActions>
        <Button onClick={onRequestClose} color="primary">Fechar</Button>
        <Button onClick={handleSave} color="primary" variant="contained" disabled={!canSave}>Salvar</Button>
      </DialogActions>

      {/* Dialog para adicionar nova opção de descrição */}
      <Dialog open={dialogOpcaoAberto} onClose={() => setDialogOpcaoAberto(false)}>
        <DialogTitle>Adicionar Nova Opção</DialogTitle>
        <DialogContent>
          <TextField
            label="Descrição da Nova Opção"
            value={novaOpcao}
            onChange={(e) => setNovaOpcao(e.target.value)}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpcaoAberto(false)} color="primary">Cancelar</Button>
          <Button onClick={handleAddOpcao} color="primary" variant="contained">Adicionar</Button>
        </DialogActions>
      </Dialog>

      {/* Dialog para adicionar novo perfil */}
      <Dialog open={dialogPerfilAberto} onClose={() => setDialogPerfilAberto(false)}>
        <DialogTitle>Adicionar Novo Perfil</DialogTitle>
        <DialogContent>
          <TextField
            label="Descrição do Novo Perfil"
            value={novoPerfil}
            onChange={(e) => setNovoPerfil(e.target.value)}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogPerfilAberto(false)} color="primary">Cancelar</Button>
          <Button onClick={handleAddPerfil} color="primary" variant="contained">Adicionar</Button>
        </DialogActions>
      </Dialog>
    </Dialog>
  );
};

export default EditRegraModalAtivos;
